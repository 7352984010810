<template>
  <form
    @submit.prevent="onSubmit"
    @change.prevent="onChange"
    @input.prevent="onInput"
  >
    <slot />
  </form>
</template>
<script>
export default {
  name: 'AppForm',

  props: {
    debounceTime: {
      type: Number,
      default: 0,
    },
  },

  emits: [
    'submit',
    'input',
    'change',
  ],

  data: () => ({
    invalidFields: [],
    debounceTimer: null,
  }),

  computed: {
    invalid() {
      return this.invalidFields.length;
    },
  },

  methods: {
    debounce(callback) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        callback();
      }, this.debounceTime);
    },

    checkValid(field) {
      const name = field.name;
      const invalidOld = this.invalidFields.includes(name);
      const invalidNew = field.getAttribute('data-invalid') === 'true';
      if (invalidOld && !invalidNew) {
        this.invalidFields = this.invalidFields.filter((item) => item !== name);
      }
      if (!invalidOld && invalidNew) {
        this.invalidFields.push(name);
      }
    },

    onSubmit(e) {
      this.checkValid(e.target);
      if (this.invalid) {
        return;
      }
      this.$emit('submit');
    },

    onInput(e) {
      this.debounce(() => {
        this.checkValid(e.target);
        if (this.invalid) {
          return;
        }
        this.$emit('input');
      });
    },

    onChange(e) {
      this.debounce(() => {
        this.checkValid(e.target);

        if (this.invalid) {
          return;
        }
        this.$emit('change');
      });
    },
  },
};
</script>
