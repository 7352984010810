<template>
  <div
    ref="inputWrap"
    class="z-input"
    :class="classes"
    :style="styles"
  >
    <div class="z-input__field-wrap">
      <label
        v-if="label"
        class="z-input__label"
        :class="{
          'z-input__label_focused': focused,
          'z-input__label_filled': isFilled,
          'z-input__label_disabled': disabled
        }"
        @click="focus"
      >
        {{ label }}<template v-if="required">*</template>
      </label>

      <div
        class="z-input__group"
        :class="classesInputGroup"
        @click="focus"
      >
        <input
          v-if="!multiLine"
          ref="input"
          v-model="valueInternal"
          class="z-input__field"
          :class="classesInput"
          :type="typeInternal"
          :placeholder="focused || !label ? placeholder : ''"
          :disabled="disabled"
          :readonly="readonly"
          :pattern="pattern"
          :required="required"
          :name="name"
          :data-invalid="!!error"
          @click="$attrs.click ? $attrs.click($event) : null"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @paste="handlePaste"
          @keydown="$emit('keydown', $event)"
        >
        <textarea
          v-else
          ref="input"
          v-model="valueInternal"
          class="z-input__field"
          :class="classesInput"
          :placeholder="placeholder"
          :disabled="disabled"
          :rows="rows"
          :readonly="readonly"
          :required="required"
          :name="name"
          :data-invalid="!!error"
          @input="textareaChangeSize"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @paste="handlePaste"
        />

        <app-icon-svg
          v-if="appendIconComputed && !(clearable && valueInternal)"
          :class="{'z-input__icon_spinning': loading}"
          size="18px"
          :icon="appendIconComputed"
          @click="appendIconClick"
        />

        <app-icon-svg
          v-if="clearable && valueInternal"
          class="z-input__clear"
          size="24px"
          icon="clear"
          @click="clear"
        />
        <span
          v-if="suffix"
          class="z-input__suffix"
        >{{ suffix }}</span>
      </div>

      <span
        v-if="type === 'password' && passwordView"
        class="z-input__suffix"
      >
        <span
          class="z-input__pwd-toggle"
          @click="passwordToggle"
        >{{ typeInternal === 'password' ? $t('labels.show') : $t('labels.hide') }} {{ toLower($t('auth.password.label')) }}</span>
      </span>
    </div>

    <div
      v-if="hint || counter || error"
      class="z-input__footer"
    >
      <div
        v-if="hint && !error"
        class="z-input__hint"
        v-html="hint"
      />

      <div
        v-if="error"
        class="z-input__error"
        v-html="error"
      />

      <div
        v-show="counter"
        class="z-input__counter"
        :class="{ warn: charCount > counter }"
      >
        {{ charCount }} / {{ counter }}
      </div>
    </div>
  </div>
</template>

<script>
import AppIconSvg from './AppIconSvg';
import StringsMixin from '../../school/Common/mixins/StringsMixin';
import { toMoney } from '../js/helpers/number-helper';

export default {
  emits: [
    'keydown',
    'update:modelValue',
    'blur',
    'change',
    'paste',
    'focus',
  ],

  props: {
    appendIcon: {
      type: String,
      default: '',
    },

    name: {
      type: String,
      default: '',
    },

    appendIconCb: {
      type: Function,
      default: () => {},
    },

    isNew: {
      type: Boolean,
      default: false,
    },

    big: {
      type: Boolean,
      default: false,
    },

    mediumBig: {
      type: Boolean,
      default: false,
    },

    outlined: {
      type: Boolean,
      default: false,
    },

    autofocus: {
      type: Boolean,
      default: false,
    },

    autoGrow: {
      type: Boolean,
      default: false,
    },

    autoWidth: {
      type: Boolean,
      default: false,
    },

    block: {
      type: Boolean,
      default: false,
    },

    counter: {
      type: Number,
      default: 0,
    },

    max: {
      type: Number,
      default: 100,
    },

    minCounter: {
      type: Number,
      default: 0,
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    errors: {
      type: Array,
      default() {
        return [];
      },
    },

    hint: {
      type: String,
      default: '',
    },

    integer: {
      type: Boolean,
      default: false,
    },

    float: {
      type: Boolean,
      default: false,
    },

    inverted: {
      type: Boolean,
      default: false,
    },

    invertedTheme: {
      type: Boolean,
      default: false,
    },

    label: {
      type: String,
      default: '',
    },

    loading: {
      type: Boolean,
      default: false,
    },

    maxWidth: {
      type: [Number, String],
      default: 0,
    },

    maxWidthInput: {
      type: [Number, String],
      default: 0,
    },

    multiLine: {
      type: Boolean,
      default: false,
    },

    notFull: {
      type: Boolean,
      default: false,
    },

    passwordView: {
      type: Boolean,
      default: false,
    },

    pattern: {
      type: [String, null],
      default: null,
    },

    placeholder: {
      type: String,
      default: '',
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    inherit: {
      type: Boolean,
      default: false,
    },

    toMoney: {
      type: Boolean,
      default: false,
    },

    rows: {
      type: [String, Number],
      default: 5,
    },

    size: {
      type: String,
      default: 'normal',
    },

    suffix: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    modelValue: {
      type: [String, Number],
      default: '',
    },
  },

  components: { AppIconSvg },

  mixins: [
    StringsMixin,
  ],

  mounted() {
    this.typeInternal = this.type;
    this.$nextTick(() => {
      this.textareaChangeSize();
    });

    if (this.autofocus) {
      this.$nextTick(() => {
        this.focus();
      });
    }
    window.addEventListener('resize', this.textareaChangeSize);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.textareaChangeSize);
  },

  data() {
    return {
      focused: false,
      typeInternal: 'text',
      errorsInner: [],
    };
  },

  computed: {
    appendIconComputed() {
      if (this.appendIcon) {
        return this.appendIcon;
      }

      if (this.loading) {
        return 'cached';
      }

      return '';
    },

    charCount() {
      return (typeof this.valueInternal === 'string') ? this.valueInternal.length : 0;
    },

    classes() {
      return {
        block: this.block,
        inherit: this.inherit,
        new: this.isNew,
        big: this.big,
        'medium-big': this.mediumBig,
        [`z-input_size_${this.size}`]: this.size,
      };
    },

    classesInput() {
      return {
        autogrow: this.autoGrow,
      };
    },

    classesInputGroup() {
      return {
        disabled: this.disabled,
        focused: this.focused,
        filled: this.isFilled,
        outlined: !this.focused && this.outlined,
        invalid: this.errors.length,
        iconed: this.appendIcon,
        'icon-cb': this.appendIconCb,
        'inline-flex': this.notFull || (this.type === 'password' && this.passwordView),
        inverted: this.inverted,
        'inverted-theme': this.invertedTheme,
        'no-label': !this.label,
        'with-suffix': this.suffix,
        'not-full': this.notFull,
      };
    },

    error() {
      return [
        ...this.errors,
        ...this.errorsInner,
      ].join(', ');
    },

    isFilled() {
      return this.valueInternal === 0
        || this.valueInternal
        || this.typeInternal === 'date';
    },

    styles() {
      const obj = {
        '--max-width-input': this.maxWidthInput ? `${this.maxWidthInput}px` : '100%',
      };
      if (this.maxWidth) {
        obj.maxWidth = `${this.maxWidth === 'none' ? this.maxWidth : `${this.maxWidth}px`}`;
      }

      return obj;
    },

    valueInternal: {
      get() {
        return this.modelValue;
      },

      set(value) {
        let result = value;
        if (this.integer && result) {
          result = parseInt(value
            .toString()
            .replaceAll(/\s/g, ''), 10) || 0;
          result = toMoney(result);
        }
        if (this.float && result) {
          result = parseFloat(value
            .toString()
            .replaceAll(/\s/g, '')
            .replaceAll(',', '.')) || 0;
        }
        if (this.toMoney && result) {
          result = toMoney(value
            .toString()
            .replaceAll(/\s/g, ''));
        }
        if (this.type === 'number' && result) {
          result = Math.max(0, Math.min(result, this.max || 100));
        }

        this.$emit('update:modelValue', result);
      },
    },
  },

  methods: {
    appendIconClick() {
      if (!this.appendIconCb) {
        return;
      }

      this.appendIconCb();
    },

    clear() {
      this.valueInternal = '';
    },

    focus() {
      this.$refs.input.focus();
      if (this.$attrs.click) {
        this.$refs.input.click();
      }
    },

    handleBlur() {
      this.focused = false;
      this.$emit('blur');
    },

    handleChange() {
      this.$emit('change', this.valueInternal);
    },

    handlePaste(e) {
      this.$emit('paste', e.clipboardData.getData('text'));
    },

    handleFocus() {
      this.$emit('focus');
      this.focused = true;
    },

    passwordToggle() {
      if (this.typeInternal === 'password') {
        this.typeInternal = 'text';
      } else {
        this.typeInternal = 'password';
      }
    },

    select() {
      this.$refs.input.select();
    },

    textareaChangeSize() {
      if (!this.multiLine || (!this.autoGrow && !this.autoWidth)) {
        return;
      }

      const { input, inputWrap } = this.$refs;
      if (!input || !inputWrap) {
        return;
      }
      if (this.autoWidth) {
        inputWrap.style.maxWidth = 'none';
        inputWrap.style.width = 'auto';
        inputWrap.classList.add('auto-width');
        inputWrap.dataset.value = this.modelValue || this.placeholder;
        const scrollWidth = input.scrollWidth || 312;
        inputWrap.style.maxWidth = `${scrollWidth}px`;
        inputWrap.style.width = '';
        inputWrap.classList.remove('auto-width');
      }
      if (this.autoGrow) {
        input.style.height = '21px'; // для автоматического уменьшения высоты
        const scrollHeight = input.scrollHeight || 21;
        input.style.height = `${scrollHeight}px`;
      }
    },
  },

  watch: {
    charCount(cur) {
      if (this.counter && cur) {
        if (this.counter < cur) {
          this.errorsInner = [this.$tc('errors.max', this.counter)];
          return;
        }
        if (this.minCounter > cur) {
          this.errorsInner = [this.$tc('errors.min', this.minCounter)];
          return;
        }
        this.errorsInner = [];
      }
    },
    type(value) {
      this.typeInternal = value;
    },

    modelValue() {
      this.$nextTick(() => {
        this.textareaChangeSize();
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/components.blocks/z-input";

.autogrow{
  overflow: hidden;
  resize: none;
}
</style>
