<template>
  <div
    id="app"
    :class="{noCustomTheme}"
  >
    <router-view />
  </div>
</template>

<script>
  import url from 'wurl';
  import sourcebuster from 'sourcebuster';
  import ClientStorageCookie from '../../../laravel/resources/assets/common/js/client-storage-cookie';
  import CONSTANTS from '../../../laravel/resources/assets/school/js/constants';

  export default {
    name: 'App',
    mounted() {
      this.setPartnerUri();
      this.saveRefererToCookie();
      this.saveUTMWithSourceBuster();
    },
    data() {
      return {
        noCustomTheme: CONSTANTS.THEME_NO_CLASS,
      };
    },
    methods: {
      setPartnerUri() {
        const params = url('?');
        if (!params || !params.p) {
          return;
        }

        const partnerUri = ClientStorageCookie.getItem(CONSTANTS.COOKIE_MARKETING_PARTNER_URI) || null;
        if (partnerUri && partnerUri !== params.p) {
          return;
        }

        ClientStorageCookie.setItem(CONSTANTS.COOKIE_MARKETING_PARTNER_URI, params.p, { domain: window.location.hostname, expires: 365 });
      },

      saveRefererToCookie() {
        const referer = url();
        if (!referer) {
          return;
        }

        const savedReferer = ClientStorageCookie.getItem(CONSTANTS.COOKIE_MARKETING_REFERER) || null;
        if (savedReferer) {
          return;
        }

        ClientStorageCookie.setItem(CONSTANTS.COOKIE_MARKETING_REFERER, referer, { domain: window.location.hostname, expires: 365 });
      },

      saveUTMWithSourceBuster() {
        sourcebuster.init({
          lifetime: 12,
        });
      },
    },
  };
</script>

<style lang="scss">
  @import "../../../laravel/resources/assets/common/css/font-variables.css";
  @import "../../../laravel/resources/assets/common/sass/variables";
  @import "../../../laravel/resources/assets/common/sass/theme-variables";

  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    box-sizing: border-box;
    font-family: var(--zen-font);
    font-feature-settings: 'lnum' 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
  }

  #app a {
    font-style: normal;
    font-weight: var(--zen-weight-regular);
    font-size: var(--zen-body2-fz);
    line-height: 1.15;
    color: $theme-zPrimary;
    text-decoration: none;
  }
</style>
