<template>
  <div>
    <app-form @submit="submit">
      <div>
        <app-input
          v-model="email"
          type="email"
          :autofocus="breakpoint.mdAndUp"
          :label="$t('auth.email')"
          block
          :errors="errors.email"
        />

        <div>
          <error-message
            :message="errorMsg"
          />

          <app-btn
            class="submit-btn"
            type="submit"
            block
            :loading="isLoading"
            :disabled="isLoading || !formValid"
          >
            {{ $t('password.reset_send') }}
          </app-btn>
        </div>
      </div>
    </app-form>

    <div
      v-if="success"
      class="success-msg zSuccess--text"
    >
      {{ $t('auth.message_sent') }}
    </div>
  </div>
</template>

<script>
  import passwordReset from '../../api/endpoints/password-reset';
  import AppInput from '../../../../../laravel/resources/assets/common/components.blocks/AppInput';
  import AppForm from '../../../../../laravel/resources/assets/common/components.blocks/AppForm';
  import AppBtn from '../../../../../laravel/resources/assets/common/components.blocks/AppBtn2';
  import ErrorableForm from '../../../../../laravel/resources/assets/school/Errors/mixins/ErrorableForm';
  import ErrorMessage from '../../../../../laravel/resources/assets/school/Errors/blocks/ErrorMessage';

  export default {
    name: 'ResetPassword',
    components: {
      ErrorMessage,
      AppInput,
      AppForm,
      AppBtn,
    },

    mixins: [
      ErrorableForm,
    ],

    data() {
      return {
        email: '',
        isLoading: false,
        errors: {
          email: [],
        },

        success: false,
      };
    },

    computed: {
      formValid() {
        return this.email !== '';
      },
    },

    methods: {
      submit() {
        if (this.isLoading) {
          return;
        }

        this.resetErrors();
        this.isLoading = true;
        const data = {
          email: this.email,
        };

        passwordReset(data)
          .then(this.showSuccess)
          .catch(this.setErrors)
          .then(() => {
            this.isLoading = false;
          });
      },

      showSuccess() {
        this.success = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .submit-btn {
    margin-top: 16px;
  }
</style>
