import { createApp, h } from 'vue';
import App from './App.vue';
import router from './router/routes';
// eslint-disable-next-line import/no-named-as-default,import/no-named-as-default-member
import BreakpointPlugin from './plugins/breakpoint';
import i18n, { computeLocale } from './i18n';

const app = createApp({
  render() {
    return h(App);
  },
  mounted() {
    /**
     * Реактивная смена локали приложения
     *
     * Актуально исключительно для dev и staging сред, чтобы было попроще тестировать виджет
     * на разных языках. Но при необходимости можно сгенерировать соответствующее событие
     * и в проде - язык UI изменится корректно
     */
    document.addEventListener('localeChange', () => {
      i18n.global.locale = computeLocale();
    });
  },
});

app.config.productionTip = false;
app.use(BreakpointPlugin);
app.use(i18n);
app.use(router);

router.isReady().then(() => {
  app.mount('#app');
});
