<template>
  <page>
    <div class="body--title">
      {{ $t('school.welcome') }}
    </div>

    <div class="register-email-wrapper">
      <register-email></register-email>
    </div>
  </page>
</template>

<script>
  import Page from "./Common/Page";
  import RegisterEmail from "./Forms/RegisterEmail";

  export default {
    name: "SignupEmail",
    components: {
      RegisterEmail,
      Page
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../laravel/resources/assets/common/sass/mixins/media-queries";

  .register-email-wrapper {
    max-width: 290px;
    margin: 0 auto;
  }

  @include mediaXsAndDown{
    .register-email-wrapper {
      max-width: none;
    }
  }
</style>
