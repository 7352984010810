import upperFirst from 'lodash-es/upperFirst';

const StringsMixin = {
  methods: {
    toLower(text) {
      return text.toLowerCase();
    },

    toUcfirst(text) {
      return upperFirst(text);
    },
  },
};

export default StringsMixin;
