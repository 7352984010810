<template>
  <div class="agreement">
    <i18n-t keypath="school.agreement.text" scope="global">
      <template #policy_link>
        <a
          :href="termsAgreement"
          target="_blank"
          class="inherit"
        >
          {{ $t('school.agreement.policy') }}
        </a>
      </template>
      <template #offer_link>
        <a
          :href="termsAgent"
          target="_blank"
          class="inherit"
        >
          {{ $t('school.agreement.terms_of_service') }}
        </a>
      </template>
    </i18n-t>
  </div>
</template>

<script>
  import { computeLocale } from '../../i18n';

  export default {
    name: 'Agreement',

    computed: {
      currentLocale() {
        return computeLocale();
      },

      termsAgent() {
        return this.currentLocale === 'en'
          ? 'https://zenclass.net/legal/privacy-policy'
          : 'https://zenclass.ru/terms/agent';
      },

      termsAgreement() {
        return this.currentLocale === 'en'
          ? 'https://zenclass.net/legal/acceptable-use'
          : 'https://zenclass.ru/terms/agreement';
      },
    },
  };
</script>

<style scoped lang="scss">
  @import "../../../../../laravel/resources/assets/common/sass/variables";

  .agreement{
    font-style: normal;
    font-weight: var(--zen-weight-regular);
    font-size: var(--zen-body2-fz);
    line-height: 1.3;
    text-align: center;
    color: var(--text-grey-color);

    span a {
      color: var(--accent_color) !important;
    }
  }
</style>
