<template>
  <span
    ref="icon"
    :style="styles"
    class="z-svg-icon"
    :class="color ? `${color}--text` : ''"
    v-bind="$attrs"
  />
</template>
<script>
export default {
  name: 'AppIconSvg',
  props: {
    icon: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '24px',
    },
    height: {
      type: String,
      default: '24px',
    },
    maxHeight: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    // заменяет цвет иконки на цвет текста родителя, соответственно начинает реагировать на hover
    currentColor: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    const icon = this.$refs.icon;
    const transition = window.getComputedStyle(icon, null).getPropertyValue('transition');
    if (transition) {
      this.transition = `${transition}, -webkit-mask-image 0s`;
    }
  },
  data: () => ({
    transition: '',
  }),
  computed: {
    heightInner() {
      return this.size || this.height;
    },
    widthInner() {
      return this.size || this.width;
    },
    pathInner() {
      return this.path || 'images/icons';
    },
    styles() {
      const styles = {
        width: this.widthInner,
        height: this.heightInner,
        transition: this.transition,
      };
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const svgUrl = `url(${require(`../../school/${this.pathInner}/${this.icon}.svg`)})`;
      if (this.currentColor) {
        styles.backgroundColor = 'currentColor';
        styles.maskSize = '100%';
        styles.maskImage = svgUrl;
      } else {
        styles.backgroundSize = '100%';
        styles.backgroundImage = svgUrl;
      }
      if (this.maxHeight) {
        styles.maxHeight = this.maxHeight;
      }
      return styles;
    },
  },
};
</script>
<style lang="scss">
.z-svg-icon{
  display: inline-block;
  align-self: center;
  justify-self: center;
  background-position: center;
  background-repeat: no-repeat;
  mask-position: center;
  mask-repeat: no-repeat;
  vertical-align: middle;
  flex-shrink: 0;
  color: var(--text-grey-color);
}

/**
 * Фикс для ситуации, когда анимация изменения цвета иконки не синхронизирована
 * с изменением цвета кнопки. См. https://github.com/vuetifyjs/vuetify/issues/9541
 */
.z-btn .z-svg-icon{
  transition: none;
}

/**
 * Если указать компоненту color="inherit", цвет отнаследуется от родителя
 */
.z-svg-icon.inherit--text{
  color: inherit;
}
</style>
