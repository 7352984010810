<template>
  <div class="app-input-pswd">
    <app-input
      v-model="valueInternal"
      v-bind="$attrs"
      :type="showPassword ? 'text' : 'password'"
      :label="$t('password.label')"
    ></app-input>

    <div
      :class="{ closed: showPassword }"
      class="eye"
      @click="toggleShowPassword"
    ></div>
  </div>
</template>

<script>
  import AppInput from "../../../../../laravel/resources/assets/common/components.blocks/AppInput";

  export default {
    name: "AppInputPassword",

    props: {
      value: [String, Number]
    },

    components: {
      AppInput
    },

    data() {
      return {
        showPassword: false,
      }
    },

    computed: {
      valueInternal: {
        get() {
          return this.value;
        },

        set(value) {
          this.$emit('input', value);
        }
      }
    },

    methods: {
      toggleShowPassword() {
        this.showPassword = !this.showPassword;
      },
    },
  }
</script>

<style scoped>
  .app-input-pswd {
    position: relative;
  }

  .eye {
    background: url("./../../assets/icons/eye.svg") center center no-repeat;
    cursor: pointer;
    height: 24px;
    position: absolute;
    right: 10px;
    top: 8px;
    width: 24px;
    z-index: 10;
  }

  .eye.closed{
    background-image: url("./../../assets/icons/eye_closed.svg");
  }
</style>
